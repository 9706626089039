import { userInfoStore } from '@/store/UserStore';
import { storeToRefs } from 'pinia';
import router from '@/router';
import moment from 'moment';
import jwt from '@/api/LocalStorage';

const userStore = userInfoStore();
const { user_info_list } = storeToRefs(userStore);

/*
    기존에 비슷한 기능이어도 공용 js 파일이 나뉘어져 있었는데 계정 관련한
    메소드들을 한 곳에 정리
*/

// 작품이 19금 일 때 사용자의 나이가 만 19세 이상일 시 작품 썸네일이 정상적으로 노출
// 사용자의 나이가 만 19세 미만일 때 작품 썸네일이 노출되지 않음

// true : 썸네일 정상적으로 노출
// false : 썸네일 노출하지 않음
export const checkAdultUser = (content) => {
	// console.log(content);
	if (content.age_limit == 19) {
		try {
			// user_info_list가 null 일 경우 로그인이 풀린 상태
			if (!user_info_list.value.birth_date) {
				return false;
			} else {
				const birth_date = moment(user_info_list.value.birth_date);
				if (moment().diff(birth_date.format('YYYYMMDD'), 'years') >= 19) {
					return true;
				} else {
					return false;
				}
			}
		} catch {
			return false;
		}
	} else {
		return true;
	}
};

// 브라우저 상에서 로그인 체크
export const checkLogin = () => {
	const ID_TOKEN_KEY = 'id_token';
	const token = jwt.getToken(ID_TOKEN_KEY);
	console.log({ token });
	if (token) {
		return true;
	} else {
		return false;
	}
};

// 쿠키에 저장된 로그인 정보를 가져와 사용
export const getLoginInfo = () => {
	let loginInfo = {};
	if (jwt.getToken('id_token')) {
		loginInfo.id = jwt.getToken('id');
		loginInfo.userNo = jwt.getToken('userNo');
		loginInfo.nickName = jwt.getToken('nickname');
	} else {
		loginInfo = null;
	}
	return loginInfo;
};
