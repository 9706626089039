<template>
	<div class="tab_wrap">
		<ul>
			<li class="genre_tab active" @click="settingGenre('ALL', $event)">전체</li>
			<li class="genre_tab" @click="settingGenre('AC', $event)">액션</li>
			<li class="genre_tab" @click="settingGenre('TR', $event)">스릴러</li>
			<li class="genre_tab" @click="settingGenre('ML', $event)">멜로</li>
			<li class="genre_tab" @click="settingGenre('RC', $event)">로맨틱코메디</li>
			<li class="genre_tab" @click="settingGenre('HR', $event)">호러</li>
		</ul>
	</div>
</template>

<script setup>
import { defineEmits } from 'vue';

const emits = defineEmits(['changeGenre']);

// 장르 세팅
const settingGenre = (genre, event) => {
	// 기존의 active 클래스 삭제 후 클릭한 것만 active 클래스 부여
	const genreTabs = document.querySelectorAll('.genre_tab');
	genreTabs.forEach((tab) => {
		tab.classList.remove('active');
	});
	event.target.classList.add('active');

	emits('changeGenre', genre);
};
</script>

<style scoped>
.genre_tab {
	cursor: pointer;
}
</style>
